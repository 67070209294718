.container {
  margin-left: auto;
  margin-right: auto;

  $gutter-size: map-get($grid-gutter, "sm");
  padding-left: $gutter-size;
  padding-right: $gutter-size;
  max-width: $content-max-width + $gutter-size;

  @each $name, $size in $breakpoints {
    @include breakpoint($name) {
      $gutter-size: map-get($grid-gutter, $name);
      padding-left: $gutter-size;
      padding-right: $gutter-size;
      max-width: $content-max-width + $gutter-size;
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));

  $gutter-size: map-get($grid-gutter, "sm");
  column-gap: $gutter-size;
  row-gap: $gutter-size;

  @each $name, $size in $breakpoints {
    @include breakpoint($name) {
      $gutter-size: map-get($grid-gutter, $name);
      column-gap: $gutter-size;
      row-gap: $gutter-size;
    }
  }
}

.grid {
  @each $fr in $grid-fractions {
    &.fr-#{$fr} {
      grid-template-columns: repeat($fr, minmax(0, 1fr));
    }
  }
}

// Layout components
body {
  position: relative;
  margin: 0 auto;
  overflow-x: hidden;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: map-get($z-indexes, header);

  background: $white;

  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);

  // fix step animation
  max-width: 100vw;
}

.main-content {
  overflow-x: visible;
  max-width: 100%;
}
