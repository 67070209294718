html {
  font-size: 16px;
  @include breakpoint(lg) {
    font-size: 20px;
  }
}

body {
  font-family: "HelveticaNeueLTPro", sans-serif;
  color: $black;
}

h1,
h2 {
  margin: rem-calc(28) 0 0.5rem 0;
  font-size: rem-calc(28);
}

p {
  margin: 0.5rem 0 0.5rem 0;
}

a {
  color: inherit;
  text-decoration: none;
}

h3,
.lead {
  margin: 0.5rem 0 0.5rem 0;
  font-size: rem-calc(22);
  font-weight: 700;
}

.bold {
  font-weight: 700;
}
