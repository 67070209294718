message {
  position: fixed;
  left: 0;
  right: 0;
  z-index: map-get($z-indexes, message);

  display: block;
  margin-top: 3 * $block-margin;

  animation: fade-out 0.4s 4s ease forwards;

  > div {
    > div {
      position: relative;
      background: $anthrazit;
      border-radius: 5px;
      color: $white;
      box-shadow: 0px 0.5rem 3rem 1rem rgba(0, 0, 0, 0.33);
      padding: 1.66rem 2rem;

      &.error,
      &.warning {
        background-color: $orange;
        color: $black;
      }

      > span {
        font-size: 1.3rem;
      }
    }
  }
}

body.loading message {
  display: none;
}
