.menu {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 16px auto;

  button.nav-toggle,
  button.nav-toggle:hover,
  button.nav-toggle:focus {
    // reset
    margin: 0.3rem;
    box-shadow: none;
    background: none;

    background-image: url("static/assets/burger.svg");
    background-repeat: no-repeat;
    background-position: center;

    &.close-button {
      margin-left: auto;
      background-image: url("static/assets/close-burger.svg");
    }
  }
}

a.logo-link {
  display: block;
  max-height: 3rem;
  img {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
  }
}

nav {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  overflow-y: auto;
  overflow-x: hidden;

  z-index: map-get($z-indexes, nav);

  padding: 1rem 0;
  background: $white;

  .nav-toggle,
  .nav-toggle:hover,
  .nav-toggle:focus {
    margin-left: auto;
  }

  transform: translateY(calc(-100vh - 1rem));
  visibility: hidden;

  &:not(.expanded) {
    animation: slide-up 0.4s ease forwards;
  }

  &.expanded {
    animation: slide-down 0.4s ease forwards;
  }

  ul:not(.language-nav) {
    list-style: none;
    padding: 0;
    margin-top: $block-margin;

    visibility: hidden;
    animation: from-left 0.4s both ease;
  }
  ul:not(.language-nav) li > a,
  .menu-backlink-wrap > a {
    display: flex;
    align-items: center;

    width: 100%;
    font-size: 1.5rem;
    border-bottom: 1px solid $black;
    margin: 0.5rem 0;
    padding: 1rem 0;

    font-weight: bold;

    &::after,
    &::before {
      width: rem-calc(15);
      height: rem-calc(25);
      background-size: contain;
      background-repeat: no-repeat;
    }

    &:hover {
      cursor: pointer;
    }
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  li > a:not(.menu-backlink) {
    justify-content: space-between;

    &::after {
      content: " ";
      background-image: url("static/assets/arrow.svg");
    }
  }

  ul.language-nav {
    display: flex;
    position: absolute;
    gap: 1rem;
    list-style: none;
    padding: 0;
    li {
    }
    a {
      font-weight: bold;
      &.active {
        text-decoration: underline;
      }
    }
  }

  a.menu-backlink {
    margin-top: $block-margin;
    align-items: center;

    &::before {
      content: " ";
      margin-right: 1rem;
      background-image: url("static/assets/arrow-back.svg");
    }
  }

  .menu-page-overlay {
    margin-bottom: 10vh;

    visibility: hidden;
    animation: from-right 0.4s both ease;
  }
}
