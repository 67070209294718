// colors
$white: #fff;
$light-gray: #d2d2d2;
$gray: #888;
$dark-gray: #333;
$black: #000;
$anthrazit: #2a2a2a;

$turquois: #005a77;
$turquois--light: #0080a8;
$turquois--dark: darken($turquois, 10%);
// $turquois--darker: darken($turquois, 20%);

$orange: #ec6329;
$orange--dark: #d14800;
$red: #d80f2a;

$breakpoints: (
  sm: 0rem,
  md: 48rem,
  lg: 64rem,
);

$grid-fractions: (1, 2, 3, 4);

$grid-gutter: (
  sm: 1rem,
  md: 1.3rem,
  lg: 1.3rem,
);

$content-max-width: 32rem;
$block-margin: 1.3rem;

$z-indexes: (
  message: 5,
  header: 10,
  nav: 15,
  modal: 20,
  loading: 30,
);
