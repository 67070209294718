form {
  textarea,
  input[type="text"] {
    border: 1px solid $gray;
    font-family: inherit;
    padding: 0.33rem;
    font-size: 1rem;
    line-height: 1;
  }
}
