div.download-button {
  @extend button;
  font-weight: normal;
  max-width: 334px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 0;
  margin-bottom: 1.3rem;
}

div.download-preview {
  margin: $block-margin 0;
  display: flex;
  justify-content: center;
  img {
    max-width: 100%;
  }
}

.download-link:hover,
.download-link:focus {
  img {
    box-shadow: 0 0 0 1px $dark-gray;
  }
}

.download-wrap a {
  text-decoration: none;
}
