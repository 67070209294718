[aria-hidden="true"],
[aria-expanded="false"] {
  button {
    visibility: hidden;
  }
}

.show-for-sr {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
